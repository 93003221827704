// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Image from "./image";

const Header = ({ siteTitle }) => (
  <header>
    <div style={{ maxWidth: `400px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    {/*<img src="images/stellar-jay-logo.png" width="300px" />*/}
    <h1>{siteTitle}</h1>
    <hr />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
