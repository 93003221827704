/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import "./stellarjay.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div className='site'>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div className="sub-head">Encourage Engagement and Education of Nature, Culture, and Civics</div>
        <hr className="symmetric"/>
        <main>{children}</main>
        <footer>
          <hr />
          © {new Date().getFullYear()}, <a href="mailto:stellarjaysoftware@gmail.com" target="_blank">stellarjaysoftware@gmail.com</a>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
